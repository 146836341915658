<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">ENROLLED </span> STUDENT(S)
    </PageHeader>

    <v-row>
      <v-col>
        <v-form>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="4">
                  <v-autocomplete
                    hide-details="auto"
                    :loading="loadProgram"
                    v-model="program"
                    :items="itemsProgram"
                    @keyup="searchProgram"
                    hide-selected
                    class="rounded-0"
                    item-text="program_name_certificate"
                    item-value="id"
                    label="Search Program"
                    outlined
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search
                          <strong>Program...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" lg="4">
                  <v-select
                    hide-details="auto"
                    :menu-props="{ down: true, offsetY: true }"
                    @click="fetchCalendar"
                    label="Academic Calendar"
                    v-model="calendar_id"
                    :items="basket_calendar"
                    :loading="iscalendarLoaded"
                    item-text="academic_calendar"
                    class="rounded-0"
                    item-value="id"
                    outlined
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Current Academic Calendar...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4">
                  <v-select
                    hide-details="auto"
                    :menu-props="{ down: true, offsetY: true }"
                    outlined
                    label="Semester"
                    v-model="semester"
                    @click="fetchSemester"
                    :loading="issemesterLoaded"
                    class="rounded-0"
                    :items="basket_semester"
                    multiple
                    item-text="semester"
                    item-value="id"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Semesters...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-select
                    v-model="level"
                    @click="fetchLevel"
                    :items="basket_level"
                    :loading="islevelLoaded"
                    item-value="id"
                    multiple
                    class="rounded-0"
                    item-text="level"
                    outlined
                    label="Level"
                    :menu-props="{ down: true, offsetY: true }"
                    hide-details="auto"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search
                          <strong>Levels...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="toggleLevel(basket_level)">
                        <v-list-item-action>
                          <v-icon
                            :color="level.length > 0 ? 'primary' : 'grey'"
                          >
                            {{ iconLevel }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Select All </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" lg="4">
                  <v-select
                    hide-details="auto"
                    :menu-props="{ down: true, offsetY: true }"
                    v-model="campus"
                    item-value="id"
                    item-text="campus_name"
                    class="rounded-0"
                    :items="basket_campus"
                    @click="fetchCampus"
                    :loading="iscampusLoaded"
                    label="Campus"
                    outlined
                    multiple
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Campus(es)...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="toggleCampus(basket_campus)">
                        <v-list-item-action>
                          <v-icon
                            :color="campus.length > 0 ? 'primary' : 'grey'"
                          >
                            {{ iconCampus }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Select All </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <!-- <v-divider class="mt-1"></v-divider> -->
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row align="center" justify="center">
                <v-col cols="12" lg="3">
                  <v-btn
                    height="45"
                    :disabled="disabled"
                    @click="searchEnrolledStudents"
                    class="title"
                    block
                    color="primary"
                  >
                    DOWNLOAD EXCEL
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <!-- <v-row v-if="response">
			<v-col>
				<v-data-table
					:headers="headers"
					:items="basketWithIndex"
					hide-default-footer
					class="elevation-1"
					item-key="id"
					:loading="loading"
				>
				</v-data-table>
			</v-col>
		</v-row> -->

    <Error v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Error>
  </v-container>
</template>

<script>
  import { computed, getCurrentInstance, reactive, toRefs, watch } from "vue";
  import PageHeader from "@/components/slots/PageHeader";
  import debounce from "@/debounce/debounce";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Error from "@/components/Error/Error";
  export default {
    components: { PageHeader, Error },

    setup() {
      const vm = getCurrentInstance
      const {
        getProgram,
        getLevel,
        getSemester,
        getCampus,
        getSession,
        getCurCalendar,
        getEnrolledStudents,
        signOut,
      } = useActions([
        "getProgram",
        "getLevel",
        "getSemester",
        "getCampus",
        "getSession",
        "getCurCalendar",
        "getEnrolledStudents",
        "signOut",
      ]);

      const {
        getters_program,
        getters_level,
        getters_semester,
        getters_campus,
        getters_session,
        getters_curcalendar,
        getters_enrolled_students,
        /* user, */
      } = useGetters([
        "getters_program",
        "getters_level",
        "getters_semester",
        "getters_campus",
        "getters_session",
        "getters_curcalendar",
        "getters_enrolled_students",
        "user",
      ]);

      let studentForm = reactive({
        disabled: true,
        basket_calendar: [],
        calendar_id: null,
        semester: [],
        session: "",
        program: "",
        campus: [],
        level: [],
        loadProgram: false,
        itemsProgram: [],
        iscalendarLoaded: false,
        basket_semester: [],
        basket_level: [],
        basket_campus: [],
        basket_session: [],
        issemesterLoaded: false,
        issessionLoaded: false,
        iscampusLoaded: false,
        islevelLoaded: false,
        isstudentstatusLoaded: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
      });

      const enrolledStudents = reactive({
        headers: [
          { text: "#", value: "index" },
          { text: "NAME", value: "student.name" },
        ],
        loading: false,
        response: false,
      });

      const { loading, response } = toRefs(enrolledStudents);

      const {
        disabled,
        loadProgram,
        itemsProgram,
        issemesterLoaded,
        issessionLoaded,
        islevelLoaded,
        iscampusLoaded,
        basket_level,
        basket_campus,
        basket_session,
        basket_semester,
        semester,
        /*	session,*/
        program,
        campus,
        level,
        iscalendarLoaded,
        basket_calendar,
        calendar_id,
        msgHeader,
        msgBody,
        msgIcon,
        deleteResponse,
      } = toRefs(studentForm);

      watch(
        () => [
          calendar_id.value,
          program.value,
          campus.value,
          semester.value,
          level.value,
        ],
        () => {
          if (
            calendar_id.value &&
            program.value &&
            campus.value.length > 0 &&
            semester.value.length > 0 &&
            level.value.length > 0
          ) {
            disabled.value = false;
          } else {
            disabled.value = true;
          }
        }
      );

      const searchProgram = debounce(() => {
        if (itemsProgram.value.length > 0) return;
        loadProgram.value = true;

        getProgram()
          .then(() => {
            itemsProgram.value = getters_program.value;
          })
          .then(() => (loadProgram.value = false));
      });

      const fetchSemester = () => {
        issemesterLoaded.value = true;
        if (getters_semester.value.length > 0) {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
          return;
        }

        getSemester().then(() => {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
        });
      };

      const fetchCampus = () => {
        iscampusLoaded.value = true;
        if (getters_campus.value.length > 0) {
          basket_campus.value = getters_campus.value;
          iscampusLoaded.value = false;
          return;
        }

        getCampus().then(() => {
          basket_campus.value = getters_campus.value;
          iscampusLoaded.value = false;
        });
      };

      const fetchLevel = () => {
        islevelLoaded.value = true;
        if (getters_level.value.length > 0) {
          basket_level.value = getters_level.value;
          islevelLoaded.value = false;
          return;
        }

        getLevel().then(() => {
          basket_level.value = getters_level.value;
          islevelLoaded.value = false;
        });
      };

      const fetchSession = () => {
        issessionLoaded.value = true;
        if (getters_session.value.length > 0) {
          basket_session.value = getters_session.value;
          issessionLoaded.value = false;
          return;
        }

        getSession().then(() => {
          basket_session.value = getters_session.value;
          issessionLoaded.value = false;
        });
      };

      const fetchCalendar = () => {
        iscalendarLoaded.value = true;
        if (getters_curcalendar.value.length > 0) {
          iscalendarLoaded.value = false;
          basket_calendar.value = getters_curcalendar.value;
          return;
        }
        getCurCalendar().then(() => {
          basket_calendar.value = getters_curcalendar.value;
          iscalendarLoaded.value = false;
        });
      };

      const selectSomeCampus = computed(() => {
        return (
          campus.value.length > 0 &&
          campus.value.length < getters_campus.value.length
        );
      });

      const selectSomeLevel = computed(() => {
        return (
          level.value.length > 0 &&
          level.value.length < getters_level.value.length
        );
      });

      const selectAllCampus = computed(() => {
        return getters_campus.value.length === campus.value.length;
      });

      const selectAllLevel = computed(() => {
        return getters_level.value.length === level.value.length;
      });

      const iconCampus = computed(() => {
        if (selectAllCampus.value) return "mdi-checkbox-marked";
        if (selectSomeCampus.value) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      });

      const iconLevel = computed(() => {
        if (selectAllLevel.value) return "mdi-checkbox-marked";
        if (selectSomeLevel.value) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      });

      const toggleCampus = (items) => {
        if (selectAllCampus.value) {
          campus.value = [];
        } else {
          campus.value = [];
          items.forEach((i) => campus.value.push(i.id));
        }
      };

      const toggleLevel = (items) => {
        if (selectAllLevel.value) {
          level.value = [];
        } else {
          level.value = [];
          items.forEach((i) => level.value.push(i.id));
        }
      };

      const searchEnrolledStudents = () => {
        loading.value = true;
        response.value = false;
        deleteResponse.value = false;
        getEnrolledStudents({
          level: level.value,
          semester: semester.value,
          campus: campus.value,
          program: program.value,
          calendar: calendar_id.value,
        })
          .then(() => {
            loading.value = false;
            response.value = true;
          })
          .catch((e) => {
            deleteResponse.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            if (e.response.status === 423) {
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
            }
          });
      };

      const basketWithIndex = computed(() => {
        return getters_enrolled_students.value.map((items, index) => ({
          ...items,
          index: index + 1,
        }));
      });

      return {
        ...toRefs(studentForm),
        ...toRefs(enrolledStudents),
        searchProgram,
        fetchSemester,
        fetchCampus,
        fetchSession,
        fetchLevel,
        fetchCalendar,
        basket_calendar,
        toggleCampus,
        iconCampus,
        toggleLevel,
        iconLevel,
        searchEnrolledStudents,
        basketWithIndex,
      };
    },
  };
</script>
